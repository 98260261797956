import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";

import { Home, type State } from "@/components/home/home";
import {
  type DashboardAnalyticsJourneyType,
  type DashboardAnalyticsScreenType,
  useAnalytics,
  usePassportProvider,
} from "@/context";
import { useGetNextPage } from "@/hooks";

export default function Index() {
  const nextPage = useGetNextPage();
  const { track, page } = useAnalytics();

  const [redirecting, setRedirecting] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const { triggerLogin, passportState } = usePassportProvider();
  const { push } = useRouter();

  let state: State = "pending";
  if (passportState.ready) state = "ready";
  if (loggingIn) state = "loggingIn";
  if (redirecting) state = "redirecting";
  if (passportState.authenticated) state = "authenticated";

  const analyticsProps = useMemo(
    () => ({
      screen: "Login" as DashboardAnalyticsScreenType,
      userJourney: "Profile" as DashboardAnalyticsJourneyType,
    }),
    [],
  );

  useEffect(() => {
    page({ ...analyticsProps, extras: { state } });
  }, [analyticsProps, page, state]);

  return (
    <Home
      state={state}
      onLogin={async () => {
        track({ ...analyticsProps, action: "Started" });
        setLoggingIn(true);
        await triggerLogin();
      }}
      onLoginFailed={() => {
        track({ ...analyticsProps, action: "Failed" });
      }}
      onLoginClosed={() => {
        setLoggingIn(false);
      }}
      onAuthenticated={() => {
        track({ ...analyticsProps, action: "Succeeded" });
        push(nextPage);
        setRedirecting(true);
      }}
    />
  );
}
