import { Body, Box, Button, Heading, Logo } from "@biom3/react";
import { useRive } from "@rive-app/react-canvas-lite";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  type DashboardAnalyticsJourneyType,
  type DashboardAnalyticsScreenType,
  useAnalytics,
} from "@/context";
import { notifyError } from "@/utils/monitoring";

export type State =
  | "pending"
  | "ready"
  | "redirecting"
  | "authenticated"
  | "loggingIn";

interface Props {
  state: State;
  onLogin?: () => Promise<void>;
  onLoginFailed?: () => void;
  onLoginClosed?: () => void;
  onAuthenticated?: () => void;
}

const USER_TRIGGERED_ERRORS = [
  "User did not authorize the request",
  "Popup closed by user",
];

export const Home = ({
  state = "ready",
  onAuthenticated = () => {},
  onLoginFailed = () => {},
  onLoginClosed = () => {},
  onLogin = async () => {},
}: Props) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { RiveComponent } = useRive({
    src: "https://biome.immutable.com/hosted-assets/rive/immutable_loader.riv",
    autoplay: true,
  });
  const loading = state !== "ready";

  const analyticsProps = useMemo(
    () => ({
      screen: "Login" as DashboardAnalyticsScreenType,
      userJourney: "Profile" as DashboardAnalyticsJourneyType,
    }),
    [],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (state === "authenticated") {
      track({
        ...analyticsProps,
        control: "Screen",
        controlType: "Effect",
        action: "Succeeded",
      });
      onAuthenticated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const onLoginClick = async () => {
    try {
      track({
        ...analyticsProps,
        action: "Request",
        control: "Click",
        controlType: "Button",
      });
      await onLogin();
    } catch (error) {
      track({
        ...analyticsProps,
        action: "Failed",
        control: "Click",
        controlType: "Button",
      });

      const err = error as Error;
      if (!err.message || !USER_TRIGGERED_ERRORS.includes(err.message)) {
        notifyError(error, "login");
      }
      onLoginFailed();
    } finally {
      onLoginClosed();
    }
  };

  return (
    <Box
      sx={{
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage: "url(/login_background.webp)",
        textAlign: "center",
        d: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          width: "600px",
          height: "600px",
          transform: "translate(-50%, -50%)",
          borderRadius: "300px",
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.78) 58.59%, rgba(0, 0, 0, 0.00) 100%)",
          backdropFilter: "blur(1px)",
        }}
      />

      {!loading && (
        <Box sx={{ zIndex: 1 }} testId="login-ready">
          <Box
            sx={{
              d: "flex",
              flexDirection: "column",
              mb: { default: "base.spacing.x8", medium: "base.spacing.x16" },
              gap: { default: "base.spacing.x4", medium: "base.spacing.x8" },
            }}
          >
            <Box>
              <Logo
                testId="logo-symbol"
                logo="PassportSymbolOutlined"
                sx={{
                  mr: "base.spacing.x3",
                  ml: "0",
                  width: "base.spacing.x24",
                }}
              />
              <Heading>{t("immutable")}</Heading>
              <Body sx={{ textTransform: "base.font.casing.uppercase" }}>
                {t("passport")}
              </Body>
            </Box>
            <Box>
              <Body>{t("application_tagline")}</Body>
            </Box>
          </Box>
          <Box
            sx={{ d: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Button
              testId="login-button"
              sx={{ pl: "base.spacing.x2" }}
              onClick={onLoginClick}
            >
              <Button.Logo
                logo="PassportSymbolOutlined"
                sx={{
                  mr: "base.spacing.x3",
                  ml: "0",
                  width: "base.icon.size.400",
                }}
              />
              {t("sign_in")}
            </Button>
          </Box>
        </Box>
      )}

      <Box
        testId="login-loading"
        sx={{
          zIndex: 1,
          display: loading ? "flex" : "none",
          flexDirection: "column",
          alignItems: "center",
          gap: "base.spacing.x4",
        }}
      >
        <Box
          id="logo"
          testId="logo-loading"
          sx={{
            gridArea: "logo",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            marginBottom: "base.spacing.x8",
          }}
        >
          <Box sx={{ width: "90px", height: "90px" }} rc={<RiveComponent />} />
        </Box>
        {(state === "redirecting" || state === "authenticated") && (
          <Body testId="login-redirecting">{t("login_active")}</Body>
        )}
        {state === "loggingIn" && (
          <Box
            testId="login-loggingIn"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "base.spacing.x4",
            }}
          >
            <Body>{t("signing_in_popup")}</Body>
            <Body>{t("signing_in_tab")}</Body>
          </Box>
        )}
      </Box>
    </Box>
  );
};
